import * as React from 'react';
import { DateTime } from 'luxon';
const Table = ({
  data,
  description = true,
  downloads = true,
  idPath = true,
  owner = false,
  toplistDownloads = false,
  number = false,
}) => {
  let i = 1;
  return (
    <table>
      <thead>
        <tr>
          {number && <th>#</th>}
          <th>Title</th>
          {description && <th>Description</th>}
          <th>Version</th>
          {idPath && <th>Name</th>}
          <th>Updated</th>
          {owner && <th>Owner</th>}
          {downloads && <th>Downloads</th>}
          {toplistDownloads && <th>22-04-28</th>}
        </tr>
      </thead>
      <tbody>
        {data.map((item) => (
          <tr key={item.id}>
            {number && <td>{i++}</td>}

            <td>
              {item.repositoryUrl ? (
                <a href={item.repositoryUrl}>{item.displayName}</a>
              ) : item.homepage ? (
                <a href={item.homepage}>{item.displayName}</a>
              ) : (
                item.displayName
              )}
            </td>
            {description && item.bundle?.plugins ? (
              <td>
                {item.description}
                <p>{item.bundle.plugins.length} plugins:</p>
                <ul>
                  {item.bundle.plugins.map((plugin) => (
                    <li key={plugin.name}>{plugin.name}</li>
                  ))}
                </ul>
              </td>
            ) : (
              description && <td>{item.description}</td>
            )}
            <td>{item.latestVersion}</td>
            {idPath && <td>{`matomo/${item.idPath}`}</td>}
            <td>{DateTime.fromSQL(item.lastUpdated).toFormat(`yyMMdd`)}</td>
            {owner && <td>{item.owner}</td>}
            {downloads && <td>{item.numDownloads}</td>}
            {toplistDownloads && <td>{item.topListNumDownloads}</td>}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
