import * as React from 'react';
import { graphql } from 'gatsby';

import Layout from '../components/Layout';
import Table from '../components/Table';

// const compareDownloads = (a, b) => {
//   if (a.numDownloads < b.numDownloads) {
//     return 1;
//   }
//   if (a.numDownloads > b.numDownloads) {
//     return -1;
//   }
//   return 0;
// };

const ToplistPage = ({ data, location }) => {
  const title = 'Toplist';

  const themes = [];
  data.themes.nodes.forEach(function (edge) {
    edge.officialDownloads = edge.numDownloads;
    edge.numDownloads = edge.topListNumDownloads;

    themes.push(edge);
  });

  // themes.sort(compareDownloads);

  const plugins = [];
  data.plugins.nodes.forEach(function (edge) {
    edge.officialDownloads = edge.numDownloads;
    edge.numDownloads = edge.topListNumDownloads;

    plugins.push(edge);
  });
  // plugins.sort(compareDownloads);

  return (
    <Layout location={location} title={title}>
      <h1>{title} </h1>
      <>
        <p>Since 2022-04-28.</p>
        <h2>{`Themes Top 10`}</h2>
        {data?.plugins?.nodes &&
          Table({ data: themes.slice(0, 10), owner: true, number: true })}
        <h2>{`Plugins Top 100`}</h2>
        {data?.themes?.nodes &&
          Table({ data: plugins.slice(0, 100), owner: true, number: true })}
      </>
    </Layout>
  );
};
export const query = graphql`
  {
    plugins: allMatomoPlugin(
      filter: { isPaid: { eq: false } }
      sort: { order: DESC, fields: topListNumDownloads }
    ) {
      nodes {
        id
        idPath
        displayName
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        topListNumDownloads
        topListNew
        repositoryUrl
        owner
      }
    }
    themes: allMatomoTheme(sort: { order: DESC, fields: topListNumDownloads }) {
      nodes {
        id
        idPath
        latestVersion
        lastUpdated
        isPaid
        isCustomPlugin
        createdDateTime
        description
        displayName
        numDownloads
        topListNumDownloads
        topListNew
        repositoryUrl
        owner
      }
    }
  }
`;

export default ToplistPage;
